/* eslint-disable react-hooks/rules-of-hooks */
import { useAuth0 } from '@auth0/auth0-react';
import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';
import NavBar from '../components/NavBar';
import config from '../globals/config/config';

const BuildingWebGLToken = () => {
  const { buildingId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { unityProvider, sendMessage, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: 'studio-unity-build/Build/studio-unity-build.loader.js',
    dataUrl: 'studio-unity-build/Build/studio-unity-build.data',
    frameworkUrl: 'studio-unity-build/Build/studio-unity-build.framework.js',
    codeUrl: 'studio-unity-build/Build/studio-unity-build.wasm',
  });

  useEffect(() => {
    const sendDataToUnity = async () => {
      const token = await getAccessTokenSilently();
      sendMessage('BridgeManager', 'LoadBuildingFromID', `${buildingId}@Bearer ${token}@${config.API_URL}`);
    };

    if (isLoaded) sendDataToUnity();
  }, [isLoaded]);

  return (
    <>
      <NavBar beforeNavigate={async () => await unload()} />
      <div className="map-container">
        <Fragment>
          {!isLoaded && <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
          <Unity
            unityProvider={unityProvider}
            style={{ height: 'calc(100vh - 58px)', width: '100vw', visibility: isLoaded ? 'visible' : 'hidden' }}
          />
        </Fragment>
      </div>
    </>
  );
};

export default BuildingWebGLToken;

import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Container, Table, Modal, Alert, Dropdown, DropdownButton } from 'react-bootstrap';
import { ICONS } from '../globals/Icons';
import NavBar from '../components/NavBar';
import Loading from '../components/Loading';
import AddModal from '../components/AddModal';
import DeleteModal from '../components/DeleteModal';
import EditModal from '../components/EditModal';
import ReUploadModal from '../components/BuildingReUploadModal';
import { GET_BUILDINGS } from '../queries';
import { CRUDMode } from '../globals/Types';
import { IMap } from 'goodmaps-utils';
import { useNavigate } from 'react-router-dom';
import './BuildingList.scss';

const BuildingList = () => {
  const navigate = useNavigate();
  const [buildingId, setBuildingId] = useState<string>('');
  const [buildingName, setBuildingName] = useState<string>('');
  const [mode, setMode] = useState<CRUDMode>(CRUDMode.none);
  const { loading, error, data } = useQuery(GET_BUILDINGS);

  const openModal = (mode: CRUDMode) => {
    setMode(mode);
  };

  const closeModal = () => {
    setMode(CRUDMode.none);
  };

  const renderData = () => {
    if (loading) {
      return <Loading />;
    }
    if (error) {
      return (
        <Container>
          <Alert variant="danger">
            <Alert.Heading>{error?.message}</Alert.Heading>
          </Alert>
        </Container>
      );
    }
    return (
      <Container>
        <div className="building-list">
          <div className="add-building-button">
            <Button variant="primary" onClick={() => openModal(CRUDMode.add)}>
              Add Building
            </Button>
          </div>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.getAllBuildings.map((b: IMap) => (
                <tr
                  key={b.id}
                  onClick={() => {
                    setBuildingId(b.id);
                    setBuildingName(b.name);
                  }}
                >
                  <td>{b.id}</td>
                  <td>{b.name}</td>
                  <td>
                    <DropdownButton
                      title={<img src={ICONS.misc.three_dot_menu} alt="menu button" />}
                      variant="primary"
                      id="buildingList-dropdown-toggle"
                    >
                      <Dropdown.Item eventKey="1" onClick={() => navigate(`/${b.id}`)}>
                        View - Unity
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2" onClick={() => navigate(`sdk/${b.id}`)}>
                        View - SDK
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="3" onClick={() => navigate(`/building/${b.id}/entities`)}>
                        List Entities
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="4" onClick={() => openModal(CRUDMode.edit)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="5" onClick={() => openModal(CRUDMode.reupload)}>
                        Re-Upload
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="6" onClick={() => openModal(CRUDMode.delete)}>
                        Delete
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Modal centered show={mode === CRUDMode.add} onHide={closeModal}>
            <AddModal onClose={closeModal} />
          </Modal>
          <Modal centered show={mode === CRUDMode.delete} onHide={closeModal}>
            <DeleteModal buildingId={buildingId} buildingName={buildingName} onClose={closeModal} />
          </Modal>
          <Modal size="lg" centered show={mode === CRUDMode.edit} onHide={closeModal}>
            <EditModal buildingId={buildingId} onClose={closeModal} />
          </Modal>
          <Modal centered show={mode === CRUDMode.reupload} onHide={closeModal}>
            <ReUploadModal buildingId={buildingId} onClose={closeModal} />
          </Modal>
        </div>
      </Container>
    );
  };

  return (
    <>
      <NavBar />
      {renderData()}
    </>
  );
};

export default BuildingList;

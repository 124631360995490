import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table, Modal, Accordion, Container, Alert, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import NavBar from '../components/NavBar';
import PoiEditModal, { editPOIFormSchema } from '../components/PoiEditModal';
import RoomEditModal, { editRoomFormSchema } from '../components/RoomEditModal';
import { useQuery } from '@apollo/client';
import { GET_ROOMS, GET_POIS } from '../queries/index';
import { EntityCRUDMode } from '../globals/Types';
import Loading from '../components/Loading';
import usePoiEditMutation from '../components/usePoiEditMutation';
import { getPOIsQueryPayload, POIFeature } from '../components/usePoiQueryHook';
import useRoomEditMutation from '../components/useRoomEditMutation';
import { getRoomsQueryPayload } from '../components/useRoomQueryHook';
import { IUnitFeature } from 'goodmaps-utils';

const EntityList = () => {
  const [mode, setMode] = useState<EntityCRUDMode>(EntityCRUDMode.none);
  const [selectedPoiId, setSelectedPoiId] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [poiList, setPoiList] = useState<POIFeature[]>([]);
  const [roomList, setRoomList] = useState<IUnitFeature[]>([]);
  const [errorEntityId, setErrorEntityId] = useState('');

  //pull buildingId from params
  let { buildingId } = useParams();
  const getPOIsQuery = useQuery<getPOIsQueryPayload>(GET_POIS, { variables: { buildingId }, notifyOnNetworkStatusChange: true });
  const getRoomsQuery = useQuery<getRoomsQueryPayload>(GET_ROOMS, { variables: { buildingId }, notifyOnNetworkStatusChange: true });

  const { onConfirmPOIEdit, isPoiMutationLoading, editedPoiId, editPoiError } = usePoiEditMutation({ poiId: selectedPoiId, getPOIsQuery });
  const { onConfirmRoomEdit, isRoomMutationLoading, editRoomData, editRoomError } = useRoomEditMutation({ roomId: selectedRoomId, getRoomsQuery });

  useEffect(() => {
    if (getPOIsQuery.data) {
      setPoiList([
        ...getPOIsQuery.data.getBuildingById.features.amenity,
        ...getPOIsQuery.data.getBuildingById.features.fixture,
        ...getPOIsQuery.data.getBuildingById.features.opening,
      ]);
    }
    if (getRoomsQuery.data) {
      setRoomList(getRoomsQuery.data.getBuildingById.features.unit);
    }
  }, [getPOIsQuery.data, getRoomsQuery.data]);

  useEffect(() => {
    if (editPoiError) {
      setErrorEntityId(selectedPoiId);
    } else if (editRoomError) {
      setErrorEntityId(selectedRoomId);
    }
  }, [editPoiError, editRoomError]);

  const openModal = (mode: EntityCRUDMode) => {
    setMode(mode);
  };

  const closeModal = () => {
    setMode(EntityCRUDMode.none);
  };

  const renderPoiStatus = (poiId: string) => {
    if (selectedPoiId === poiId) {
      if (isPoiMutationLoading) return <Spinner animation="border" />;
    }
    if (editedPoiId === poiId) {
      return <div>Edited!</div>;
    }
    if (errorEntityId === poiId) {
      return <div style={{ color: 'red' }}>Failed :(</div>;
    }
  };

  const renderRoomStatus = (roomId: string) => {
    if (selectedRoomId === roomId) {
      if (isRoomMutationLoading) return <Spinner animation="border" />;
    }
    if (editRoomData?.id === roomId) {
      return <div>Edited!</div>;
    }
    if (errorEntityId === roomId) {
      return <div style={{ color: 'red' }}>Failed :(</div>;
    }
  };

  const renderPoiList = () => {
    return (
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Status</th>
            <th>POI ID</th>
            <th>Name</th>
            <th>Spanish Name</th>
            <th>Actions</th>
            <th>Modal</th>
          </tr>
        </thead>
        <tbody>
          {poiList.map((poi: any) => {
            return (
              <tr
                key={poi.id}
                onClick={() => {
                  if (!isPoiMutationLoading) setSelectedPoiId(poi.id);
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: poi.properties.name?.en || '',
                    spanishName: poi.properties.name?.esMX || '',
                    category: poi.properties.category,
                  }}
                  validationSchema={editPOIFormSchema}
                  onSubmit={values => {
                    onConfirmPOIEdit(values);
                  }}
                >
                  {formik => (
                    <>
                      <td>{renderPoiStatus(poi.id)}</td>
                      <td>{poi.id === selectedPoiId ? `${poi.id}*` : poi.id} </td>
                      <td>
                        <Form.Control as="input" id="name" {...formik.getFieldProps('name')} />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="form-feedback-error">{'Please provide a valid name.'}</div>
                        ) : null}
                      </td>
                      <td>
                        <Form.Control as="input" id="spanishName" {...formik.getFieldProps('spanishName')} />
                      </td>
                      <td>
                        <Button
                          disabled={isPoiMutationLoading || getPOIsQuery.loading || !formik.dirty}
                          onClick={() => formik.handleSubmit()}
                          variant="primary"
                        >
                          Save
                        </Button>
                      </td>
                      <td>
                        <Button onClick={() => openModal(EntityCRUDMode.editPoi)} variant="secondary">
                          Edit
                        </Button>
                      </td>
                    </>
                  )}
                </Formik>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderRoomList = () => {
    return (
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Status</th>
            <th>Room ID</th>
            <th>Name</th>
            <th>Spanish Name</th>
            <th>Actions</th>
            <th>Modal</th>
          </tr>
        </thead>
        <tbody>
          {roomList.map((room: any) => {
            return (
              <tr
                key={room.id}
                onClick={() => {
                  if (!isRoomMutationLoading) setSelectedRoomId(room.id);
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: room.properties.name?.en || '',
                    spanishName: room.properties.name?.esMX || '',
                    category: room.properties.category,
                  }}
                  validationSchema={editRoomFormSchema}
                  onSubmit={values => {
                    onConfirmRoomEdit(values);
                  }}
                >
                  {formik => (
                    <>
                      <td>{renderRoomStatus(room.id)}</td>
                      <td>{room.id === selectedRoomId ? `${room.id}*` : room.id} </td>
                      <td>
                        <Form.Control as="input" id="name" {...formik.getFieldProps('name')} />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="form-feedback-error">{'Please provide a valid name.'}</div>
                        ) : null}
                      </td>
                      <td>
                        <Form.Control as="input" id="spanishName" {...formik.getFieldProps('spanishName')} />
                      </td>
                      <td>
                        <Button
                          disabled={isRoomMutationLoading || getRoomsQuery.loading || !formik.dirty}
                          onClick={() => formik.handleSubmit()}
                          variant="primary"
                        >
                          Save
                        </Button>
                      </td>
                      <td>
                        <Button onClick={() => openModal(EntityCRUDMode.editRoom)} variant="secondary">
                          Edit
                        </Button>
                      </td>
                    </>
                  )}
                </Formik>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderData = () => {
    if ((getPOIsQuery.loading && !getPOIsQuery.previousData) || (getRoomsQuery.loading && !getRoomsQuery.previousData)) {
      return <Loading />;
    }
    if (getPOIsQuery.error) {
      return (
        <Container>
          <Alert variant="danger">
            <Alert.Heading>{getPOIsQuery.error.message}</Alert.Heading>
          </Alert>
        </Container>
      );
    } else if (getRoomsQuery.error) {
      return (
        <Container>
          <Alert variant="danger">
            <Alert.Heading>{getRoomsQuery.error.message}</Alert.Heading>
          </Alert>
        </Container>
      );
    }
    return (
      <>
        <Container>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <td>Building Entities</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header onClick={() => {}}>POIs</Accordion.Header>
                      <Accordion.Body>{renderPoiList()}</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Rooms</Accordion.Header>
                      <Accordion.Body>{renderRoomList()}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </td>
              </tr>
            </tbody>
          </Table>
          <Modal size="lg" centered show={mode === EntityCRUDMode.editPoi} onHide={closeModal}>
            <PoiEditModal poiId={selectedPoiId} onClose={closeModal} />
          </Modal>
          <Modal size="lg" centered show={mode === EntityCRUDMode.editRoom} onHide={closeModal}>
            <RoomEditModal roomId={selectedRoomId} onClose={closeModal} />
          </Modal>
        </Container>
      </>
    );
  };

  return (
    <>
      <NavBar />
      {renderData()}
    </>
  );
};

export default EntityList;
